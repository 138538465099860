@media print {

  // prevents fixed header being multiplied on all pages when printing
  header {
    position: absolute !important;
  }

  // .recipe-print is only set on recipe sites
  .recipe-print {

    // ----- hide
    header,
    footer,
    .m-media,
    .m-tags,
    .article-preface,
    .m-recipe-overview__meta,
    .m-recipe-overview,
    .o-sentiment,
    .o-recipe-ingredients--portions__button,
    .o-recipe-collection,
    .o-sharebuttons,
    .text-banner,
    .main-container,
    .carousel-react {
      display: none !important;
    }

    // ----- no top/bot space
    .l-body__main,
    .t-heading,
    .o-recipe--steps,
    .o-recipe-steps,
    .o-recipe-steps--heading,
    .o-recipe-ingredients--portions,
    .o-recipe-ingredients--portion-text,
    #recipe-part,
    #ingredients {
      margin-top: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    // ----- edits
    body {
      font-size: 125% !important;
      line-height: 1.5 !important;
    }

    .o-recipe--steps {
      page-break-before: always;
    }

    .t-heading--tinekjokken,
    .o-recipe-ingredients--heading {
      margin-bottom: 1rem !important;
    }

    #ingredients {
      margin-bottom: 0 !important;
    }

    .m-tip {
      font-size: 16px !important;
      text-align: center !important;
    }
  }
}
